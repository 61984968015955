import { render, staticRenderFns } from "./SalesAppraisal.vue?vue&type=template&id=8b2d03f2&scoped=true&"
import script from "./SalesAppraisal.vue?vue&type=script&lang=ts&"
export * from "./SalesAppraisal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b2d03f2",
  null
  
)

export default component.exports